<template>
  <div class="top-block">
    <div class="content">
      <div class="top-block__text-container">
        <div class="top-block__title" v-html="title"></div>
        <div class="top-block__subtitle" v-html="subtitle"></div>
        <div v-if="description" class="top-block__description" v-html="description"></div>
      </div>
    </div>
    <div class="top-block__image-container">
      <picture>
        <source
          v-for="(img, ind) in images"
          :key="ind"
          :srcset="img.src"
          :media="img.media"
        />
        <img class="top-block__image" :src="images[images.length - 1].src" />
      </picture>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBlock",
  props: {
    title: String,
    subtitle: String,
    description: String,
    images: Array,
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
}

.top-block {
  position: relative;
  height: 400px;

  @media screen and (max-width: 767px) {
    height: auto;
  }

  @media screen and (min-width: 1920px) {
    height: 497px;
  }

  &__text-container {
    height: 100%;
    padding: 64px 0;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      padding: 24px 0;
    }
  }

  &__title {
    position: relative;
    z-index: 2;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #ffffff;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
      color: #1f1f1f;
    }
  }

  &__subtitle {
    margin-top: 16px;
    position: relative;
    z-index: 2;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #ffffff;

    @media screen and (max-width: 767px) {
      margin-top: 8px;
      font-size: 18px;
      line-height: 22px;
      color: #1f1f1f;

      & br {
        display: none;
      }
    }
  }

  &__description {
    margin-top: auto;
    max-width: 592px;
    position: relative;
    z-index: 2;
    padding-top: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #ffffff;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
      color: #3c4242;
      border-top: 0;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 767px) {
      position: static;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      // content: "";
      background-image: linear-gradient(
        90deg,
        #020202 0%,
        rgba(64, 68, 69, 0) 84.48%
      );

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
</style>
<style>
@media screen and (max-width: 767px) {
  .top-block__subtitle br {
    display: none;
  }
}
</style>